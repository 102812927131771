import '../styles/index.scss';
import * as Rellax from 'rellax';

if (process.env.NODE_ENV === 'development') {
    require('../index.html');
}

var rellax = new Rellax('.rellax', {
    breakpoints: [375, 768, 1200]
});

const header = document.getElementById('header');

window.addEventListener('scroll', (e) => {
    if (window.scrollY > 10) {
        if (!header.classList.contains('scrolled')) {
            header.classList.add('scrolled');
        }
    } else {
        header.classList.remove('scrolled');
    }
});

document.querySelectorAll("[href^='#']").forEach(
    link => link.addEventListener('click', e => smoothScroll(e))
);

document.querySelectorAll("[href='https://app.odontozen.com/auth/register']").forEach(
    link => link.addEventListener('click', e => {
        e.stopPropagation();
        e.preventDefault();
        const position = e.target.dataset?.location;
        gtag('event', 'sign_up', {
            'event_category': 'engagement',
            'event_label': position
        });
        window.amplitude.track('Click Signup Button', {position});
        window.location.href = 'https://app.odontozen.com/auth/register?ampDeviceId=' + window.amplitude.getDeviceId() + '&ampSessionId='+ window.amplitude.getSessionId();
    })
);

const emailContact = document.getElementById('email-contact');
const email = ['support', 'odontozen.com'];
emailContact.setAttribute('href', 'mailto:' + email.join('@'));
emailContact.getElementsByTagName('span')[0].innerHTML = email.join('@');

var smoothScroll = e => {
    e.preventDefault();
    document.querySelector(e.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
    });
};

class Video {
    constructor(videoWrapper, name) {
        this.name = name;
        this.videoWrapper = videoWrapper;
        this.video = this.videoWrapper.querySelector("video");
        this.formatVideoPlayButton();
        this.renderVideoPlayButton();
    }

    renderVideoPlayButton() {
        this.video.classList.add('has-media-controls-hidden');
        this.videoPlayButton = this.videoWrapper.querySelector(".video-overlay-play-button");
        this.videoPlayButton.addEventListener('click', this.hideVideoPlayButton.bind(this));
    }

    formatVideoPlayButton() {
        this.videoWrapper.insertAdjacentHTML('beforeend', '\
        <svg class="video-overlay-play-button" viewBox="0 0 200 200" alt="Play video">\
            <circle cx="100" cy="100" r="90" fill="none" stroke-width="15" stroke="#fff"/>\
            <polygon points="70, 55 70, 145 145, 100" fill="#fff"/>\
        </svg>\
    ');
    }

    hideVideoPlayButton() {
        this.video.play();
        this.videoPlayButton.classList.add('is-hidden');
        this.video.classList.remove('has-media-controls-hidden');
        this.video.setAttribute('controls', 'controls');
        gtag('event', 'video_played', {
            'event_category': 'engagement',
            'event_label': this.name
        });
        window.amplitude.track('Video Played', {name: this.name});
    }

}

new Video(document.getElementById('video1'), 'Lema');
new Video(document.getElementById('video2'), 'Edgar');

// Updated Plan class with monthly/yearly toggle support
class Plan {
    constructor(planWrapper, basicPrice) {
        this.planWrapper = planWrapper;
        this.priceDiv = planWrapper.querySelector('.price');
        this.userdDiv = planWrapper.querySelector('.plan-users'); // may be null for plans without dynamic users
        this.basicPrice = basicPrice;
        this.patientsPrice = 0;
        this.dentistsPrice = 0;
        this.addonsPrice = 0;
        this.isYearly = false;  // false = monthly, true = yearly

        const patientsSelect = planWrapper.querySelector('.plan-patients');
        if (patientsSelect) {
            patientsSelect.addEventListener('change', (event) => {
                this.patientsPrice = Number(event.target.value);
                this.setPrice();
            });
        }

        const dentistsSelect = planWrapper.querySelector('.plan-dentists');
        if (dentistsSelect) {
            dentistsSelect.addEventListener('input', (event) => {
                this.dentistsPrice = Number(event.target.value);
                this.setPrice();
            });
        }

        // Attach addon module event listeners
        const addonElements = planWrapper.querySelectorAll('.addon-module');
        addonElements.forEach((el) => {
            el.addEventListener('click', () => {
                el.classList.toggle('selected');
                this.updateAddonsPrice();
                this.setPrice();
            });
        });
    }

    updateAddonsPrice() {
        let total = 0;
        const selectedAddons = this.planWrapper.querySelectorAll('.addon-module.selected');
        selectedAddons.forEach((el) => {
            total += Number(el.getAttribute('data-price'));
        });
        this.addonsPrice = total;
    }

    setPrice() {
        const baseSum = this.basicPrice + this.patientsPrice + this.dentistsPrice + this.addonsPrice;
        let totalPrice;
        let formatted, intPart, decPart;
        if (this.isYearly) {
            totalPrice = (baseSum + 0.99) * 10 + 0.09;
            formatted = totalPrice.toFixed(2);
            [intPart, decPart] = formatted.split('.');
            this.priceDiv.innerHTML = '<b>$</b><span class="plan-price">' + intPart + '</span><sup>.' + decPart + '</sup><b>/año*</b>';
        } else {
            totalPrice = baseSum + 0.99;
            formatted = totalPrice.toFixed(2);
            [intPart, decPart] = formatted.split('.');
            this.priceDiv.innerHTML = '<b>$</b><span class="plan-price">' + intPart + '</span><sup>.' + decPart + '</sup><b>/mes*</b>';
        }
        if (this.userdDiv) {
            this.userdDiv.textContent = (this.dentistsPrice / 5 + 2).toString();
        }
    }

    updateAddonModuleTexts() {
        const addons = this.planWrapper.querySelectorAll('.addon-module');
        addons.forEach((el) => {
            const basePrice = Number(el.getAttribute('data-price'));
            const multiplier = this.isYearly ? 10 : 1;
            const newPrice = basePrice * multiplier;
            const modulePriceSpan = el.querySelector('.module-price');
            if(modulePriceSpan){
                modulePriceSpan.textContent = '+$' + newPrice + (this.isYearly ? '/año' : '/mes');
            }
        });
    }

    setYearly(isYearly) {
        this.isYearly = isYearly;
        this.setPrice();
        this.updateAddonModuleTexts();
    }
}

// Initialize only plan-1 as plan-2 has been removed
const plan1Element = document.getElementById('plan-1');
let plan1;
if(plan1Element){
    plan1 = new Plan(plan1Element, 19);
}

// Initialize plan-clinic (Clínica) with base price 79
const planClinicElement = document.getElementById('plan-2');
let planClinic;
if(planClinicElement){
    planClinic = new Plan(planClinicElement, 79);
}

// Attach listener to the monthly/yearly toggle to update both plans
const priceToggle = document.getElementById('priceToggle');
if(priceToggle) {
    priceToggle.addEventListener('change', () => {
        const isYearly = priceToggle.checked;
        if(plan1) {
            plan1.setYearly(isYearly);
        }
        if(planClinic) {
            planClinic.setYearly(isYearly);
        }
    });
}
